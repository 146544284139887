<template>
    <div v-if="false">    
        <div 
            class="banner position-fixed d-flex" 
            :class="{'close-icon-video': videoIsVisible}" 
            @mouseleave="bannerIsVisible = false">
            <div 
                :class="{'banner-text-visible': bannerIsVisible}"
                v-if="!videoIsVisible"
                class="banner-text d-flex flex-column align-items-start justify-content-center px-3 position-relative 
                overflow-hidden border" 
                @click="toggleShowVideo">
                <p class="banner-text-main font-weight-bold m-0">Ako sa prihlásiť?</p>
                <p class="banner-text-second m-0">Pozrieť video</p>
            </div>
            <img 
                v-if="!videoIsVisible" 
                src="@/theme/svg/questionmark.svg"
                @mouseover="bannerIsVisible = true"
                class="position-relative rounded-circle"/>

            <img 
                v-else 
                src="@/theme/svg/close.svg" 
                @click="toggleShowVideo" 
                class="close-icon position-relative rounded-circle" />
        </div>
        <div 
            v-if="videoIsVisible" 
            class="video-background" 
            @touchmove.prevent>
            <iframe 
                :src="videoSrc" 
                class="position-fixed video-frame"
                frameborder="0"
                allow="autoplay; encrypted-media"
                >
            </iframe>
        </div>
    </div>
</template>
<script>

export default {
    props: ['videoSrc'],

    data(){
        return {
            videoIsVisible: false,
            bannerIsVisible: false,
        }
    },

    methods: {
        toggleShowVideo(){
            this.videoIsVisible = !this.videoIsVisible
        },
    },
}
</script>
<style scoped lang="sass">
.video-frame
    right: 62px
    bottom: 122px
    z-index: 100
    height: 230px
    width: 400px
    transition: all 0.5s

.video-frame:hover
    height: 300px
    width: 525px
    transition: all 0.5s

.banner
    cursor: pointer
    right: 62px
    bottom: 62px
    line-height: 16px
    z-index: 1040
    img   
        background-color: #333333
        height: 48px
        width: 48px
        padding: 10px
    &-text
        max-width: 0px
        width: 0px
        margin-right: 20px
        text-overflow: ellipsis
        background-color: white
        white-space: nowrap
        border-radius: 30px 0px 0px 30px
        right: -48px
        opacity: 0
        padding: 0
        line-height: 16px
        transition: all 0.8s
        height: 48px
        &-main
            font-size: 17px
        &-second
            font-size: 15px
    &-text-visible
        max-width: 200px
        width: 200px
        opacity: 1
        transition: all 0.8s


@media only screen and (max-width: 920px)
    .banner
        display: none !important
    .video-frame
        height: 58vw
        width: 100vw
        right: 0
        top: calc(50% - 29vw)

    .video-frame:hover
        height: calc( 100vw / 1.7 )
        width: 100vw

    .video-background
        position: fixed
        z-index: 1031
        left: 0
        top: 0
        width: 100vw
        height: 100vh
        overflow: hidden
        background-color: rgba(0,0,0,0.6)

    .close-icon 
        height: 20px !important
        width: 20px !important
        background-color: transparent !important
        padding: 0px !important
        right: 0px !important

    .close-icon-video
        right: 16px !important
        bottom: unset !important
        top: 16px !important
    
    .banner
        right: 16px !important
        bottom: 16px !important
</style>